import React, { Component } from 'react'

import SEO from '../components/seo'

class IndexPage extends Component {
  render() {
    return (
      <>
        <SEO title='MSA Training' description='' />
      </>
    )
  }
}

export default IndexPage

